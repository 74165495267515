/* globals SUPPORT_EMAIL, SUPPORT_PHONE */
import { h } from 'preact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons'

import JumpToProperty from './jump_to_property'

const email = SUPPORT_EMAIL
const phone = SUPPORT_PHONE

const Footer = () => (
  <footer class='position-relative z-index-10 d-print-none'>
    <div class='pt-5 pb-5 bg-gray-200 text-muted'>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-4 mb-5 mb-lg-0'>
            <h6 class='text-uppercase text-dark mb-3'>Contact Us</h6>
            <p>
              Reservations Unlimited
              <br />312 W. Main St.
              <br />Red River, NM 87558
            </p>
            <p>
              <a href='mailto:{email}' class='text-muted'>{email}</a>
              <br />Phone: <a href='tel:+1-{phone}' class='text-muted'>{phone}</a>
              <br />Fax: <a href='tel:+1-575-754-6679' class='text-muted'>575-754-6679</a>
            </p>
            <p class='mb-0'>Find us on social media:</p>
            <ul class='list-inline'>
              <li class='list-inline-item'>
                <a href='https://twitter.com/stayinredriver' rel='noopener noreferrer' target='_blank' title='twitter' class='text-muted text-hover-primary'>
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li class='list-inline-item'>
                <a href='https://www.facebook.com/reservationsunlimited' rel='noopener noreferrer' target='_blank' title='facebook' class='text-muted text-hover-primary'>
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li class='list-inline-item'>
                <a href='https://www.instagram.com/reservationsunlimited' rel='noopener noreferrer' target='_blank' title='instagram' class='text-muted text-hover-primary'>
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li class='list-inline-item'>
                <a href='https://www.pinterest.com/reservationsunl' rel='noopener noreferrer' target='_blank' title='pinterest' class='text-muted text-hover-primary'>
                  <FontAwesomeIcon icon={faPinterest} />
                </a>
              </li>
              <li class='list-inline-item'>
                <a href='https://www.youtube.com/user/redriverreservations' rel='noopener noreferrer' target='_blank' title='youtube' class='text-muted text-hover-primary'>
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
            </ul>
          </div>
          <div class='col-lg-4 col-md-6 mb-5 mb-lg-0'>
            <h6 class='text-uppercase text-dark mb-3'>Resources</h6>
            <ul class='list-unstyled'>
              <li>
                <a href='/about-us' class='text-muted'>About Us</a>
              </li>
              <li>
                <a href='https://blogs.reservationsunlimited.com' rel='noopener noreferrer' target='_blank' class='text-muted'>Blog</a>
              </li>
              <li>
                <a href='https://blogs.reservationsunlimited.com/news' rel='noopener noreferrer' target='_blank' class='text-muted'>News</a>
              </li>
              <li>
                <a href='https://explore.reservationsunlimited.com/attractions/' rel='noopener noreferrer' target='_blank' class='text-muted'>Attractions</a>
              </li>
              <li>
                <a href='/events' class='text-muted'>Events</a>
              </li>
              <li>
                <a href='https://guides.reservationsunlimited.com/red-river-guides' rel='noopener noreferrer' target='_blank' class='text-muted'>Guides</a>
              </li>
            </ul>
            <h6 class='text-uppercase text-dark mt-5 mb-3'>For Owners</h6>
            <ul class='list-unstyled'>
              <li>
                <a href='https://app.janiis.com/#/owner-portal/login' rel='noopener noreferrer' target='_blank' class='text-muted'>Owner Portal</a>
              </li>
            </ul>
          </div>
          <div class='col-lg-4 col-md-6 mb-5 mb-lg-0'>
            <JumpToProperty />
            <h6 class='text-uppercase text-dark mb-3'>Legal</h6>
            <ul class='list-unstyled'>
              <li>
                <a href='/assets/downloads/RU-Rental-Terms-and-Conditions.pdf' target='_blank' class='text-muted'>Rental Agreement Terms &amp;&nbsp;Conditions&nbsp;(PDF)</a>
              </li>
              <li>
                <a href='https://www.ada.gov/regs2010/service_animal_qa.html' target='_blank' class='text-muted' rel='noreferrer'>Service Animals</a>
              </li>
              {/*
              <li>
                <a href='/terms' class='text-muted'>Terms of Use</a>
              </li>
              <li>
                <a href='/policies' class='text-muted'>Privacy and Rental Policies</a>
              </li>
              */}
            </ul>
            <dl>
              <dt>Brokerage</dt>
              <dd>Reservations Unlimited, LLC</dd>
              <dt>NM Qualifying Broker</dt>
              <dd>David Wilcox, #20388</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class='py-4 font-weight-light bg-gray-800 text-gray-300'>
      <div class='container'>
        <div class='row align-items-center'>
          <div class='col-md-6 text-center text-md-left'>
            <p class='text-sm mb-md-0'>&copy; {new Date().getFullYear()} Reservations Unlimited. All rights reserved.</p>
          </div>
          <div class='col-md-6 text-center text-md-right'>
            <img src='/assets/equal-housing-opportunity.svg' width='50' height='50' alt='Equal Housing Opportunity' />
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
